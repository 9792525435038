import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import { IStrapiBanner, IStrapiContentPosition } from '../../../types/strapi'
import transformStrapiLink from '../../../utils/transformStrapiLink'
import Link from '../../link'
import Image from '../../image'
import { BannerButton, BannerWrapper, ContentWrapper } from './MenuBanner.styled'
import useSectionTheme from '../../../hooks/useSectionTheme'
import { BoxProps } from '@mui/material/Box'
import Title from '../../title/Title'

export interface IMenuBannerProps extends IStrapiBanner {
  sx?: BoxProps['sx']
}

const MenuBanner = (props: IMenuBannerProps) => {
  const { theme, picture, button, title, link, contentPosition, sx } = props

  const mergedTheme = useSectionTheme(theme)

  if (!link) {
    return null
  }

  return (
    <ThemeProvider theme={mergedTheme}>
      <BannerWrapper sx={sx}>
        <Link {...transformStrapiLink(link)}>
          <Box px={4} height='100%'>
            <Stack sx={{ height: '100%' }} direction={contentPosition === IStrapiContentPosition.RIGHT ? 'row' : 'row-reverse'} spacing={4}>
              {picture && (
                <Box py={4}>
                  <Image image={picture} sx={{ height: '100%' }} />
                </Box>
              )}

              <ContentWrapper>
                <Title title={title} fontSize={16} fontWeight={700} lineHeight={'20px'} color='text.secondary' />

                {button && (
                  <BannerButton>{button}</BannerButton>
                )}
              </ContentWrapper>
            </Stack>
          </Box>
        </Link>
      </BannerWrapper>
    </ThemeProvider>
  )
}

export default React.memo(MenuBanner)
