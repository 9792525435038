import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const InstagramIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M24.0004 7.51591C17.242 7.51591 10.8243 6.91518 8.37295 13.2014C7.36041 15.7977 7.50745 19.1696 7.50745 23.9977C7.50745 28.2344 7.37157 32.2162 8.37295 34.7921C10.8187 41.082 17.2886 40.4795 23.9967 40.4795C30.4684 40.4795 37.1412 41.1527 39.6223 34.7921C40.6367 32.1698 40.4878 28.8481 40.4878 23.9977C40.4878 17.559 40.8433 13.4023 37.7182 10.2815C34.554 7.11976 30.2748 7.51591 23.993 7.51591H24.0004ZM22.5225 10.486C36.62 10.4637 38.4143 8.89776 37.4241 30.6521C37.0723 38.3462 31.2092 37.5019 24.0023 37.5019C10.8615 37.5019 10.4837 37.1262 10.4837 23.9902C10.4837 10.7018 11.526 10.4935 22.5225 10.4823V10.486ZM32.8043 13.2218C31.7118 13.2218 30.8258 14.1071 30.8258 15.1988C30.8258 16.2906 31.7118 17.1758 32.8043 17.1758C33.8969 17.1758 34.7829 16.2906 34.7829 15.1988C34.7829 14.1071 33.8969 13.2218 32.8043 13.2218ZM24.0004 15.5336C19.323 15.5336 15.5315 19.3239 15.5315 23.9977C15.5315 28.6714 19.323 32.4599 24.0004 32.4599C28.6779 32.4599 32.4675 28.6714 32.4675 23.9977C32.4675 19.3239 28.6779 15.5336 24.0004 15.5336ZM24.0004 18.5038C31.2688 18.5038 31.2781 29.4916 24.0004 29.4916C16.7339 29.4916 16.7227 18.5038 24.0004 18.5038Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default InstagramIcon
