import React from 'react'
import { styled } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import Link from '../link'
import { IStrapiSocial, IStrapiSocialProps } from '../../types/strapi'
import castIconSize from '../../utils/castIconSize'
import { useIsMobile } from '../../hooks/useIsMobile'

export type ISocialLinkComponentProps = {
  social: IStrapiSocial
  iconComponent: React.ReactNode
  socialProps?: IStrapiSocialProps
}

const SocialLinkComponent: React.FC<ISocialLinkComponentProps> = ({ social, socialProps, iconComponent, ...rest }) => {
  const { name, url } = social

  return (
    <ButtonBase
      href={url}
      LinkComponent={Link}
      aria-label={name}
      {...rest}
    >
      {iconComponent}
    </ButtonBase>
  )
}

export default styled(SocialLinkComponent)(({ theme, socialProps }) => {
  const { color, background, hoverColor, hoverBackground, size } = socialProps || {}

  const isMobile = useIsMobile('md')
  const castedSize = castIconSize(size, isMobile)

  const defaultColor = theme.palette.common.white
  const defaultBackground = theme.palette.brand.charcoalGray
  const defaultHoverColor = theme.palette.common.white
  const defaultHoverBackground = theme.palette.brand.brandedRed

  const iconColor = color || defaultColor
  const iconBackground = background || defaultBackground
  const iconHoverColor = hoverColor || defaultHoverColor
  const iconHoverBackground = hoverBackground || defaultHoverBackground

  return ({
    'borderRadius': '3px',
    'maxHeight': castedSize,
    '& svg': {
      'width': castedSize,
      'height': castedSize,
      'color': iconColor,
    },
    'background': iconBackground,
    'transition': 'all ease-in-out .2s',
    ':hover': {
      'transform': 'scale(1.15)',
      'background': iconHoverBackground,
      '& svg': {
        color: iconHoverColor,
      },
    },
  })
})
