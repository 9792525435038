import React from 'react'
import { IStrapiSectionBackground } from '../../types/strapi'
import BackgroundImage from '../background-image/BackgroundImage'
import { SectionBgColor, SectionOverlay, StyledSection } from './Section.styled'
import useIsLazySection from '../../hooks/useIsLazySection'
import { useSectionsContext } from '../../hooks/useSectionsContext'
import { ISectionData } from '../../utils/renderSection'

export interface ISectionProps {
  background?: IStrapiSectionBackground
  section?: ISectionData
  children: React.ReactNode
}

const Section: React.FC<ISectionProps> = ({ background, section, children }) => {

  const { modifiersMap } = useSectionsContext()

  const modifiers = section ? modifiersMap.get(section) : undefined
  const paddingTop = modifiers?.extraTopPadding ? modifiers.extraTopPadding + 'px' : undefined
  const paddingBottom = modifiers?.extraBottomPadding ? modifiers.extraBottomPadding + 'px' : undefined

  return (
    <StyledSection
      sx={{
        paddingTop,
        paddingBottom,
      }}
    >
      <SectionBgColor />

      {background && (
        <BackgroundImage {...background} sx={{ zIndex: -1 }} />
      )}

      {background?.overlay && (
        <SectionOverlay color={background.overlay} />
      )}

      {children}
    </StyledSection>
  )
}

export default Section
