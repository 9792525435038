import React from 'react'
import { IStrapiSocial, IStrapiSocialProps } from '../../types/strapi'
import { SocialType } from '../../constants/socials'
import SocialLink from '../social-link/SocialLink'
import LinkedInIcon from '../../icons/socials/Linkedin'
import TelegramIcon from '../../icons/socials/Telegram'
import TwitterIcon from '../../icons/socials/Twitter'
import WhatsAppIcon from '../../icons/socials/Whatsapp'
import FacebookIcon from '../../icons/socials/Facebook'
import MediumIcon from '../../icons/socials/Medium'
import MessengerIcon from '../../icons/socials/Messenger'
import InstagramIcon from '../../icons/socials/Instagram'
import VKIcon from '../../icons/socials/Vk'
import YoutubeIcon from '../../icons/socials/Youtube'
import GoodFirmsIcon from '../../icons/socials/GoodFirms'
import XIcon from '../../icons/socials/X'
import BehanceIcon from '../../icons/socials/Behance'
import { Stack } from '@mui/material'

export interface ISocialsComponentProps {
  socials: Array<IStrapiSocial>
  socialProps: IStrapiSocialProps
}

const getSocialIcon = (icon: SocialType) => {
  switch (icon) {
    case SocialType.FACEBOOK:
      return <FacebookIcon />

    case SocialType.LINKEDIN:
      return <LinkedInIcon />

    case SocialType.TELEGRAM:
      return <TelegramIcon />

    case SocialType.TWITTER:
      return <TwitterIcon />

    case SocialType.WHATSAPP:
      return <WhatsAppIcon />

    case SocialType.MEDIUM:
      return <MediumIcon />

    case SocialType.MESSENGER:
      return <MessengerIcon />

    case SocialType.INSTAGRAM:
      return <InstagramIcon />

    case SocialType.VK:
      return <VKIcon />

    case SocialType.YOUTUBE:
      return <YoutubeIcon />

    case SocialType.GOOD_FIRMS:
      return <GoodFirmsIcon />

    case SocialType.X:
      return <XIcon />

    case SocialType.BEHANCE:
      return <BehanceIcon />

    default:
      throw new Error(`Not found Icon component for: ${icon}`)
  }
}

const castSocialsGap = (gap: IStrapiSocialProps['gap']) => {
  switch (gap) {
    case 'small':
      return 2

    case 'medium':
      return 4

    case 'large':
      return 6

    default:
      return 4
  }
}

const SocialsBlock: React.FC<ISocialsComponentProps> = (props) => {
  const { socials, socialProps } = props

  return (
    <Stack direction='row' flexWrap='wrap' spacing={castSocialsGap(socialProps?.gap)}>
      {socials.map(social => {
        try {
          const icon = getSocialIcon(social.icon)
          return (
            <SocialLink social={social} key={social.name} socialProps={socialProps} iconComponent={icon} />
          )
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
          return null
        }
      })}
    </Stack>
  )
}

export default SocialsBlock
