import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const GoodFirms: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path d='M13.3798 14.0838C13.3798 13.1989 14.3845 13.2995 14.3845 13.2995H24.9734V19.8157C24.9332 24.3006 20.5731 24.9643 20.5731 24.9643H13.3798V19.8157H19.3675C19.9904 19.8157 19.9703 19.2324 19.9703 19.2324V18.0458H13.3798V14.0838Z' fill='currentColor' />
      <path d='M11.7925 6.98438H24.7725L24.7524 11.9319H12.978C12.978 11.9319 11.9733 12.1129 11.9733 13.1185V24.9844H6.99033V11.952C6.99033 11.952 7.09079 8.09052 11.7925 6.98438Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default GoodFirms
