import React from 'react'
import ReactDOM from 'react-dom/client'
import ThemeWrapper from './src/theme/ThemeWrapper'
import PageLayout from './src/layouts/page-layout/PageLayout'
import { GatsbyBrowser } from 'gatsby'
import './src/styles/fonts.css'
import './src/styles/index.css'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeWrapper>
    {element}
  </ThemeWrapper>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <PageLayout>
    {element}
  </PageLayout>
)


export const replaceHydrateFunction: GatsbyBrowser['replaceHydrateFunction'] = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

const FILTERED_CASES_PAGES_REGEXP = /\/cases\/(service|industry)/

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ pathname, prevRouterProps }) => {
  const prevPathname = prevRouterProps?.location?.pathname || ''

  if (prevPathname !== pathname && FILTERED_CASES_PAGES_REGEXP.test(prevPathname) && FILTERED_CASES_PAGES_REGEXP.test(pathname)) {
    return false // prevent scroll when filters applied
  }

  return true // default
}
