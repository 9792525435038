import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const BannerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
}))

export const BannerButton = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '12px',
  color: theme.palette.text.secondary,
  borderRadius: 4,
  border: '1px solid',
  borderColor: theme.palette.text.secondary,
  padding: theme.spacing(2),
  marginRight: 'auto',
  marginTop: theme.spacing(3),
}))

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  justifyContent: 'space-around',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}))
