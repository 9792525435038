import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const MediumIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M27.068 24C27.068 29.799 22.3518 34.5 16.534 34.5C10.7163 34.5 6 29.799 6 24C6 18.201 10.7162 13.5 16.534 13.5C22.3517 13.5 27.068 18.201 27.068 24Z' fill='currentColor' />
      <path d='M38.6238 24.0016C38.6238 29.4604 36.2657 33.8856 33.3568 33.8856C30.4479 33.8856 28.0898 29.4604 28.0898 24.0016C28.0898 18.5428 30.4479 14.1177 33.3568 14.1177C36.2657 14.1177 38.6238 18.5429 38.6238 24.0016Z' fill='currentColor' />
      <path d='M43.3494 24.0002C43.3494 28.891 42.52 32.8558 41.4969 32.8558C40.4738 32.8558 39.6445 28.891 39.6445 24.0002C39.6445 19.1093 40.4738 15.1445 41.4969 15.1445C42.5201 15.1445 43.3494 19.1093 43.3494 24.0002Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default MediumIcon
