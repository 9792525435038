import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const BehanceIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path d='M19.2334 9.42773H24.8399V10.8247H19.2334V9.42773Z' fill='currentColor' />
      <path d='M27.0702 17.2784C27.0702 14.3623 24.815 11.998 22.0351 11.998C19.2552 11.998 17 14.3623 17 17.2784C17 20.1945 19.0925 22.5567 22.0351 22.5567C24.272 22.5567 26.1836 21.5711 26.8323 19.2048H24.4713C24.2546 19.6099 23.9319 19.9484 23.5379 20.1841C23.1439 20.4197 22.6933 20.5437 22.2344 20.5427C20.9146 20.5427 19.7534 19.5958 19.8226 18.0034H27.0316C27.0702 17.7712 27.0702 17.5309 27.0702 17.2784ZM19.8307 16.2419C20.0056 14.9304 20.9309 14.0935 22.0473 14.0935C23.1637 14.0935 24.0809 14.9304 24.2639 16.2419H19.8307Z' fill='currentColor' />
      <path d='M16.1379 17.4994C15.9549 15.4753 13.6692 14.7931 13.6692 14.7931C14.5375 14.3858 15.5726 13.5529 15.5624 11.7181C15.5462 9.07081 12.7887 8.5332 11.902 8.5332H4.70117V22.3033H11.2431C12.1521 22.3033 13.1201 22.1831 13.8868 21.8003C15.9549 20.7821 16.3169 19.5256 16.1379 17.4994ZM7.72711 10.9097H10.8222C10.8222 10.9097 12.6524 10.8669 12.6524 12.4879C12.6524 14.1088 10.8222 14.064 10.8222 14.064H7.72711V10.9097ZM11.0052 19.9655H7.72711V16.2002H11.0052C11.0052 16.2002 13.1852 16.1493 13.1852 18.0818C13.1852 20.0144 11.0052 19.9655 11.0052 19.9655Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default BehanceIcon
