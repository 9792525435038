import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const FacebookIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M40.7752 24.836L40.0525 30.2817C40.0225 30.5302 39.8887 30.7604 39.6765 30.9287C39.4642 31.097 39.1883 31.1916 38.9011 31.1946H32.3683L32.3356 47.3397C32.3378 47.531 32.2534 47.7154 32.1006 47.853C31.9478 47.9906 31.7389 48.0703 31.519 48.075H24.9004C24.7904 48.0751 24.6815 48.0558 24.5802 48.0183C24.4789 47.9809 24.3874 47.926 24.3111 47.857C24.2349 47.788 24.1754 47.7062 24.1364 47.6167C24.0973 47.5272 24.0794 47.4318 24.0838 47.3361V31.1946H19.1842C19.0303 31.1932 18.8782 31.1655 18.7367 31.1129C18.5951 31.0604 18.4668 30.9841 18.3592 30.8885C18.2515 30.7928 18.1665 30.6796 18.1091 30.5554C18.0517 30.4312 18.023 30.2983 18.0246 30.1644L18.0001 24.7188C17.998 24.5845 18.0262 24.451 18.0833 24.3262C18.1405 24.2014 18.2253 24.0875 18.3329 23.9912C18.4406 23.8949 18.5691 23.818 18.7109 23.7648C18.8528 23.7117 19.0053 23.6834 19.1597 23.6815H24.0838V18.4242C24.0838 12.3214 28.2485 9 34.3567 9H39.3625C39.5173 9.00139 39.6702 9.02935 39.8126 9.08228C39.9549 9.1352 40.0838 9.21205 40.1919 9.30842C40.3 9.40479 40.3852 9.51878 40.4426 9.64385C40.4999 9.76892 40.5283 9.90261 40.5262 10.0373V14.6197C40.5283 14.7543 40.4999 14.888 40.4426 15.0131C40.3852 15.1382 40.3 15.2522 40.1919 15.3485C40.0838 15.4449 39.9549 15.5218 39.8126 15.5747C39.6702 15.6276 39.5173 15.6556 39.3625 15.657H36.2921C32.9726 15.657 32.3275 17.0779 32.3275 19.1311V23.6815H39.6238C39.7898 23.6834 39.9534 23.7157 40.1039 23.7765C40.2545 23.8372 40.3885 23.925 40.4973 24.034C40.606 24.1431 40.687 24.2709 40.7349 24.4091C40.7828 24.5474 40.7966 24.6929 40.7752 24.836Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default FacebookIcon
