const castIconSize = (size?: 'small' | 'medium' | 'large', isMobile = false): number => {
  switch (size) {
    case 'small':
      return 32

    case 'medium':
      return 40

    case 'large':
      return isMobile ? 40 : 48

    default:
      return 32
  }

}

export default castIconSize
