import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const TelegramIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M37.125 12.8281L32.9925 34.4019C32.9925 34.4019 32.4143 35.8977 30.826 35.1803L21.2913 27.6098L21.2471 27.5875C22.535 26.3899 32.522 17.0912 32.9585 16.6697C33.6342 16.0169 33.2148 15.6283 32.4302 16.1214L17.6781 25.8228L11.9868 23.8398C11.9868 23.8398 11.0911 23.5099 11.0049 22.7925C10.9176 22.074 12.0162 21.6854 12.0162 21.6854L35.2181 12.2598C35.2181 12.2598 37.125 11.3922 37.125 12.8281V12.8281Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default TelegramIcon
