import React from 'react'
import Image from '../image'
import { IStrapiBackground, IStrapiMixMode, IStrapiSectionBackground } from '../../types/strapi'
import { useIsMobile } from '../../hooks/useIsMobile'
import useIsLazySection from '../../hooks/useIsLazySection'
import { SxProps, useTheme } from '@mui/material'

export type IBackgroundImageProps = IStrapiBackground & {
  sx?: SxProps
}

const BackgroundImage = (props: IBackgroundImageProps) => {

  const { image, size, mobileOpacity, desktopOpacity, xPosition, yPosition, blur, sx } = props

  const { breakpoints } = useTheme()
  const isMobile = useIsMobile('md')
  const isLazy = useIsLazySection()

  const safeXPosition = xPosition || 'center'
  const safeYPosition = yPosition || 'center'

  const safeMobileOpacity = mobileOpacity ?? 1
  const safeDesktopOpacity = desktopOpacity ?? 1
  const opacityStep = (safeDesktopOpacity - safeMobileOpacity) / 4

  if (!image) {
    return null
  }

  return (
    <Image
      image={image}
      isLazy={isLazy}
      allowInlineSvg={false}
      cover
      sx={{
        position: 'absolute',
        opacity: isMobile ? safeMobileOpacity : safeDesktopOpacity,
        ...castBackgroundPosition(props),
        ...(props.mixMode === IStrapiMixMode.OVERLAY && {
          mixBlendMode: 'overlay',
        }),
        ...(opacityStep && {
          [breakpoints.down('xl')]: {
            opacity: Math.round((safeDesktopOpacity - opacityStep) * 10) / 10,
          },
          [breakpoints.down('lg')]: {
            opacity: Math.round((safeDesktopOpacity - (opacityStep * 2)) * 10) / 10,
          },
          [breakpoints.down('md')]: {
            opacity: Math.round((safeDesktopOpacity - (opacityStep * 3)) * 10) / 10,
          },
          [breakpoints.down('sm')]: {
            opacity: safeMobileOpacity,
          },
        }),
        ...(blur && {
          filter: 'opacity(35%) blur(6px)',
        }),
        ...sx,
      }}
      imageProps={{
        objectFit: castImageSize(size),
        objectPosition: `${safeXPosition} ${safeYPosition}`,
      }}
    />
  )
}

export default BackgroundImage

const castBackgroundPosition = (background: IBackgroundImageProps) => {
  const { size, yPosition, xPosition, image } = background

  if (size === 'auto') {
    return {
      width: parseInt(image?.width || '') || '100%',
      height: parseInt(image?.height || '') || '100%',
      left: xPosition === 'left' ? 0 : 'unset',
      right: xPosition === 'right' ? 0 : 'unset',
      top: yPosition === 'top' ? 0 : 'unset',
      bottom: yPosition === 'bottom' ? 0 : 'unset',
      ...(xPosition === 'center' && { left: '50%', transform: 'translateX(-50%)' }),
      ...(yPosition === 'center' && { top: '50%', transform: 'translateY(-50%)' }),
    }
  }

  return { width: '100%', height: '100%', top: 0, left: 0 }
}

const castImageSize = (size?: IStrapiSectionBackground['size']): React.CSSProperties['objectFit'] => {
  switch (size) {
    case 'cover':
      return 'cover'

    case 'contain':
      return 'contain'

    case 'auto':
    default:
      return 'none'
  }
}
