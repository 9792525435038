import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const YoutubeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M12.1109 34.0156C11.5716 33.8561 11.0794 33.5722 10.6761 33.1879C10.2727 32.8037 9.96993 32.3304 9.7933 31.8079C8.78402 29.1076 8.48497 17.832 10.4288 15.6061C11.0757 14.882 11.9891 14.4362 12.9707 14.3654C18.1853 13.8181 34.2965 13.891 36.1469 14.5479C36.6673 14.7127 37.1428 14.9904 37.5377 15.36C37.9326 15.7297 38.2367 16.1819 38.4271 16.6826C39.5299 19.4741 39.5672 29.6185 38.2776 32.3006C37.9355 32.9987 37.3628 33.5642 36.6515 33.9062C34.7077 34.8549 14.6902 34.8367 12.1109 34.0156V34.0156ZM20.3908 28.7245L29.7361 23.9807L20.3908 19.2004V28.7245Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default YoutubeIcon
