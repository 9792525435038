import React, { ReactNode } from 'react'
import Button, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import Link from '../link'

interface IButtonWithChildren {
  children: ReactNode
  label?: never
}

interface IButtonWithLabel {
  children?: never
  label: string
}

export type ILinkButtonProps = (IButtonWithChildren | IButtonWithLabel) & {
  url: string
  newTab?: boolean
  buttonProps: MuiButtonProps
}

const LinkButton: React.FC<ILinkButtonProps> = (props) => {
  const { children, label, url, newTab, buttonProps } = props

  if (!url) {
    return null
  }

  if (url.startsWith('#')) {
    const handleScroll = () => {
      const target = document.getElementById(url.slice(1))
      target?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    return (
      <Button
        onClick={handleScroll}
        {...buttonProps}
      >
        {children || label}
      </Button>
    )
  }

  return (
    <Button
      LinkComponent={Link}
      href={url}
      {...newTab && { target: '_blank' }}
      {...buttonProps}
    >
      {children || label}
    </Button>
  )
}

export default LinkButton
