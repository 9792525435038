import React from 'react'
import { SxProps } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image'
import { IStrapiImage } from '../../types/strapi'
import transformStrapiImage from '../../utils/transformStrapiImage'

export enum IImageMaxSize {
  CONTAINER = 'container',
  IMAGE = 'image',
}

export type IImageProps = {
  image?: IStrapiImage
  isLazy?: boolean
  cover?: boolean
  sx?: SxProps
  imageProps?: React.CSSProperties
  allowInlineSvg?: boolean
  maxSize?: IImageMaxSize
}

const Image = (props: IImageProps) => {

  const { image, isLazy = true, cover, sx = { width: '100%' }, imageProps = {}, allowInlineSvg = true, maxSize = IImageMaxSize.IMAGE } = props

  if (!image) {
    return null
  }

  const widthFixed = image.width || image.fields?.width
  const heightFixed = image.height || image.fields?.height

  const wrapperStyles: SxProps = {
    position: 'relative',

    ...(cover ? {
      width: '100%',
      height: '100%',
    } : {
      aspectRatio: widthFixed && heightFixed ? `${widthFixed} / ${heightFixed}` : undefined,
      ...(maxSize === IImageMaxSize.CONTAINER && {
        maxHeight: '100%',
        maxWidth: '100%',
      }),
      ...(maxSize === IImageMaxSize.IMAGE && {
        maxHeight: heightFixed,
        maxWidth: widthFixed,
      }),
    }),
  }

  const mergedWrapperStyles = {...wrapperStyles, ...sx}

  const imageStyles = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    objectFit: cover ? 'cover' : 'contain',
    ...imageProps,
  }

  const { localFile } = image

  // Inline svg (only if not lazy)
  if (localFile?.svg && !isLazy && allowInlineSvg) {
    return (
      <ImageWrapper sx={mergedWrapperStyles}>
        <Box
          dangerouslySetInnerHTML={{ __html: localFile.svg.content }}
          sx={imageStyles}
        />
      </ImageWrapper>
    )
  }

  // Gatsby images
  const gatsbyImage = localFile ? getImage(localFile) : null
  if (gatsbyImage) {
    return (
      <ImageWrapper sx={mergedWrapperStyles}>
        <StyledGatsbyImage
          image={gatsbyImage}
          alt={image.alternativeText || image.name || image.url}
          loading={isLazy ? 'lazy' : 'eager'}
          imgStyle={imageStyles as GatsbyImageProps['imgStyle']}
          wrapperStyles={wrapperStyles}
        />
      </ImageWrapper>
    )
  }

  // Lazy svg and rest images
  return (
    <ImageWrapper sx={mergedWrapperStyles}>
      <Box
        component='img'
        {...transformStrapiImage({ ...props, image })}
        sx={imageStyles}
        loading={isLazy ? 'lazy' : 'eager'}
      />
    </ImageWrapper>
  )
}

export interface IStyledGatsbyImage {
  wrapperStyles?: any
}

export const StyledGatsbyImage = styled(GatsbyImage, { shouldForwardProp: prop => prop !== 'wrapperStyles' })<IStyledGatsbyImage>(({ wrapperStyles }) => ({
  ...wrapperStyles,
  '& img[data-main-image]': {
    transition: 'none',
    transform: 'none',
  },
}))

export const ImageWrapper = styled(Box)(() => ({
  display: 'inline-block',
  verticalAlign: 'top',
  overflow: 'hidden',
}))

export default Image
