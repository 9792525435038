import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const MessengerIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M16.1507 32.2509V38L20.7127 34.419C21.9096 34.733 23.1555 34.9194 24.4604 34.9194C31.8872 34.9194 37.911 29.337 37.911 22.4597C37.911 15.5823 31.8872 10 24.4604 10C17.0238 10 11 15.5823 11 22.4597C11 26.433 13.021 29.965 16.1507 32.2509ZM22.9888 19.0945L26.5109 22.4597L32.9761 19.0945L25.932 26.7666L22.459 23.4015L16.1507 26.5115L22.9888 19.0945Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default MessengerIcon
