import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const XIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='33' height='33' viewBox='0 0 33 33' {...props}>
      <path d='M17.9027 14.4686L25.3482 6H23.5838L17.119 13.3532L11.9555 6H6L13.8082 17.1193L6 26H7.76443L14.5915 18.2348L20.0445 26H26L17.9023 14.4686H17.9027ZM15.4861 17.2173L14.695 16.1101L8.40018 7.29967H11.1103L16.1902 14.4099L16.9813 15.5172L23.5847 24.7594H20.8746L15.4861 17.2177V17.2173Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default XIcon
