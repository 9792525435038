import Container, { ContainerProps } from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const FooterWrapper = styled('footer')(({ theme }) => ({
  background: theme.palette.background.default,
}))

export const FooterContainer = styled(Container)<ContainerProps >(({ theme }) => ({
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

export const FooterCopyright = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  fontWeight: 400,
  fontStyle: 'normal',
}))
