import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Link from '../../link'
import HoverMenu from 'material-ui-popup-state/HoverMenu'

export const SubMenuTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: 500,
  padding: '25px 20px',
  transition: 'color ease-in-out .1s',
  cursor: 'default',

  [theme.breakpoints.down('xl')]: {
    padding: '25px 15px',
  },
}))

export const TopMenuLink = styled(Link)(({ theme }) => ({
  'color': theme.palette.text.primary,
  'fontWeight': 500,
  'fontSize': 14,
  'whiteSpace': 'nowrap',
  'transition': 'color ease-in-out .1s',
  'padding': '25px 20px',
  ':hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('xl')]: {
    padding: '25px 15px',
  },
}))

export const MenuContainer = styled(Container)(() => ({
  position: 'relative',
  paddingTop: 24,
  paddingBottom: 24,
}))

export const TabPanel = styled(Box)(() => ({
  position: 'relative',
}))

export const HoverSection = styled(HoverMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    width: '100%',
    maxWidth: '100%',
    boxShadow: '0px 4px 40px 0px rgba(79, 79, 79, 0.25)',
    background: theme.palette.background.default,
    backdropFilter: 'blur(10px)',
  },
}))

export enum IBannerPlacement {
  LEFT,
  RIGHT
}

export interface IBannerContainerProps {
  placement: IBannerPlacement
}

export const BannerContainer = styled(Box, { shouldForwardProp: prop => prop !== 'placement' })<IBannerContainerProps>(({ theme, placement }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 18,
  bottom: 18,
  display: 'flex',

  ...(placement === IBannerPlacement.LEFT ? { left: 12 } : { right: 12 }),

  [theme.breakpoints.up('lg')]: {
    width: '28%',
  },
}))
