export enum SocialType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  TELEGRAM = 'telegram',
  VK = 'vk',
  WHATSAPP = 'whatsapp',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  MESSENGER = 'messenger',
  MEDIUM = 'medium',
  GOOD_FIRMS = 'goodfirms',
  X = 'X',
  BEHANCE = 'behance',
}
