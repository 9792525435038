import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const WhatsappIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M34.6494 14.2109C31.9329 11.5039 28.311 10 24.4878 10C16.5396 10 10.1006 16.4167 10.1006 24.3372C10.1006 26.8438 10.8049 29.3503 12.0122 31.4557L10 38.875L17.6463 36.8698C19.7591 37.9727 22.0732 38.5742 24.4878 38.5742C32.436 38.5742 38.875 32.1576 38.875 24.237C38.7744 20.5273 37.3658 16.918 34.6494 14.2109ZM31.4299 29.4505C31.128 30.2526 29.7195 31.0547 29.0152 31.1549C28.4116 31.2552 27.6067 31.2552 26.8018 31.0547C26.2988 30.8542 25.5945 30.6536 24.7896 30.2526C21.1677 28.7487 18.8537 25.1393 18.6524 24.8385C18.4512 24.638 17.1433 22.9336 17.1433 21.1289C17.1433 19.3242 18.0488 18.5221 18.3506 18.1211C18.6524 17.7201 19.0549 17.7201 19.3567 17.7201C19.5579 17.7201 19.8598 17.7201 20.061 17.7201C20.2622 17.7201 20.564 17.6198 20.8659 18.3216C21.1677 19.0234 21.8719 20.8281 21.9726 20.9284C22.0732 21.1289 22.0732 21.3294 21.9726 21.5299C21.8719 21.7305 21.7713 21.931 21.5701 22.1315C21.3689 22.332 21.1677 22.6328 21.0671 22.7331C20.8658 22.9336 20.6646 23.1341 20.8659 23.4349C21.0671 23.8359 21.7713 24.9388 22.878 25.9414C24.2866 27.1445 25.3933 27.5456 25.7957 27.7461C26.1982 27.9466 26.3994 27.8464 26.6006 27.6458C26.8018 27.4453 27.5061 26.6432 27.7073 26.2422C27.9085 25.8411 28.2104 25.9414 28.5122 26.0417C28.814 26.1419 30.625 27.0443 30.9268 27.2448C31.3293 27.4453 31.5305 27.5456 31.6311 27.6458C31.7317 27.9466 31.7317 28.6484 31.4299 29.4505Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default WhatsappIcon
