import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LinkedinIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M17.2912 13.0909C17.2912 13.9 17.0513 14.6909 16.6018 15.3637C16.1522 16.0364 15.5133 16.5608 14.7658 16.8704C14.0183 17.18 13.1957 17.2611 12.4022 17.1032C11.6086 16.9454 10.8797 16.5557 10.3076 15.9836C9.73545 15.4115 9.34583 14.6826 9.18798 13.889C9.03013 13.0954 9.11115 12.2729 9.42078 11.5254C9.73041 10.7779 10.2548 10.139 10.9275 9.68944C11.6002 9.23993 12.3912 9 13.2003 9C14.2853 9 15.3258 9.43101 16.093 10.1982C16.8602 10.9654 17.2912 12.0059 17.2912 13.0909Z' fill='currentColor' />
      <path d='M16.3655 19.9091V37.9827C16.3662 38.116 16.3406 38.2482 16.2901 38.3716C16.2396 38.495 16.1651 38.6072 16.0711 38.7017C15.9771 38.7962 15.8653 38.8713 15.7422 38.9224C15.6191 38.9736 15.487 39 15.3537 39H11.0419C10.9086 39.0003 10.7765 38.9743 10.6532 38.9235C10.53 38.8726 10.418 38.7979 10.3237 38.7036C10.2294 38.6094 10.1547 38.4974 10.1038 38.3741C10.053 38.2509 10.027 38.1188 10.0273 37.9854V19.9091C10.0273 19.64 10.1342 19.3819 10.3245 19.1917C10.5148 19.0014 10.7728 18.8945 11.0419 18.8945H15.3537C15.6223 18.8953 15.8797 19.0025 16.0693 19.1926C16.259 19.3828 16.3655 19.6405 16.3655 19.9091Z' fill='currentColor' />
      <path d='M38.9015 29.3184V38.0675C38.9018 38.19 38.878 38.3115 38.8312 38.4248C38.7845 38.5381 38.7158 38.6411 38.6291 38.7278C38.5424 38.8145 38.4394 38.8832 38.3261 38.9299C38.2128 38.9767 38.0913 39.0005 37.9688 39.0002H33.3324C33.2098 39.0005 33.0884 38.9767 32.975 38.9299C32.8617 38.8832 32.7587 38.8145 32.6721 38.7278C32.5854 38.6411 32.5167 38.5381 32.4699 38.4248C32.4232 38.3115 32.3993 38.19 32.3997 38.0675V29.5884C32.3997 28.3229 32.7706 24.0465 29.0915 24.0465C26.2415 24.0465 25.6606 26.9729 25.546 28.2875V38.0675C25.5461 38.3125 25.4497 38.5477 25.2777 38.7222C25.1057 38.8968 24.8719 38.9966 24.6269 39.0002H20.1488C20.0264 39.0002 19.9052 38.976 19.7922 38.9291C19.6792 38.8822 19.5766 38.8135 19.4902 38.7268C19.4038 38.6402 19.3353 38.5373 19.2888 38.4242C19.2422 38.311 19.2184 38.1898 19.2188 38.0675V19.8302C19.2184 19.7078 19.2422 19.5866 19.2888 19.4734C19.3353 19.3603 19.4038 19.2575 19.4902 19.1708C19.5766 19.0842 19.6792 19.0154 19.7922 18.9685C19.9052 18.9216 20.0264 18.8975 20.1488 18.8975H24.6269C24.8743 18.8975 25.1116 18.9957 25.2865 19.1706C25.4614 19.3456 25.5597 19.5828 25.5597 19.8302V21.4065C26.6178 19.8193 28.186 18.5947 31.5324 18.5947C38.9451 18.5947 38.9015 25.5165 38.9015 29.3184Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default LinkedinIcon
