import React, { useEffect } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { isClient } from '../../utils/isClient'

type PageLayoutProps = {
  children: React.ReactNode
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {

  useEffect(() => {
    if (!isClient()) {
      return
    }

    new ResizeObserver(() => {
      const fixed100vw = document.documentElement.clientWidth
      document.documentElement.style.setProperty('--fixed100vw', `${fixed100vw}px`)
    }).observe(document.documentElement)
  }, [])

  return (
    <>
      <Header />

      <main>
        {children}
      </main>

      <Footer />
    </>
  )
}

export default PageLayout
