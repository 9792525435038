import { styled } from '@mui/material'

export const StyledSection = styled('section')(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  position: 'relative',
}))

export interface SectionOverlayProps {
  color: string
}

export const SectionOverlay = styled('div', { shouldForwardProp: prop => prop !== 'color' })<SectionOverlayProps>(({ color }) => ({
  position: 'absolute',
  backgroundColor: color,
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  zIndex: -1,
}))

export const SectionBgColor = styled('div')(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.background.default,
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  zIndex: -1,
}))
