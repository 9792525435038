import React from 'react'
import { IStrapiMenuSection } from '../../../types/strapi'
import Stack from '@mui/material/Stack'
import { MenuLink, LinkIconWrapper } from './MenuSection.styled'
import transformStrapiLink from '../../../utils/transformStrapiLink'
import Image from '../../image'

export interface IMenuSectionProps {
  section: IStrapiMenuSection
  onMenuClose: () => void
}

const MenuSection: React.FC<IMenuSectionProps> = ({ section, onMenuClose }) => {
  const { links } = section

  return (
    <Stack spacing={4}>
      {links?.map(({ link, icon, iconFile, label }, index) => (
        <MenuLink {...transformStrapiLink(link)} key={index} onClick={onMenuClose}>
          <LinkIconWrapper>
            <Image image={{ ...icon, localFile: iconFile }} isLazy={false} />
          </LinkIconWrapper>
          {label}
        </MenuLink>
      ))}
    </Stack>
  )
}

export default React.memo(MenuSection)
