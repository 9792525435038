import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const VkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='48' height='48' viewBox='0 0 48 48' {...props}>
      <path d='M25.476 33.25C15.1407 33.25 9.24563 26.024 9 14H14.1771C14.3471 22.8253 18.1638 26.5636 21.1869 27.3343V14H26.0617V21.6114C29.047 21.2838 32.1835 17.8153 33.2416 14H38.1163C37.7176 15.9787 36.9228 17.8523 35.7817 19.5034C34.6406 21.1546 33.1776 22.5479 31.4844 23.5961C33.3744 24.5539 35.0439 25.9096 36.3825 27.5737C37.721 29.2377 38.6984 31.1724 39.25 33.25H33.884C33.3888 31.4455 32.3824 29.8301 30.9909 28.6064C29.5994 27.3827 27.8847 26.605 26.0617 26.3709V33.25H25.476V33.25Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default VkIcon
