import { graphql, useStaticQuery } from 'gatsby'
import { IStrapiBlogPost } from '../types/strapi'

export interface IBlogPostsResponse {
  allStrapiBlogPost: {
    nodes: Array<IStrapiBlogPost>
  }
}

export const useLatestBlogPosts = (postsLimit = 3) => {
  const { allStrapiBlogPost }: IBlogPostsResponse = useStaticQuery(graphql`
    query {
      allStrapiBlogPost(
        sort: { date:DESC }
      ) {
        nodes {
          title
          slug
          url
          featuredImage {
            publicURL
            childImageSharp {
              gatsbyImageData (
                width: 400
              )
            }
          }
          image {
            ...StrapiBlogImage
          }
        }
      }
    }
  `)

  const limitedPosts = allStrapiBlogPost.nodes.slice(0, postsLimit)

  return limitedPosts
}
